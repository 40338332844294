import React from 'react'

import { Link, graphql } from 'gatsby'

import Layout from '../components/layout'

import '../styles/common-styles.css'
import '../styles/video-styles.css'

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark

  return (
    <Layout>
      <div className='pageTitle blogTemplateTitle'>{frontmatter.title}</div>
      <hr className='mx-8 mt-2 mb-8 md:my-4 md:mx-64 text-white border-gray-500' />
      <div className='content text-gray-700'>
        <span>{frontmatter.date}</span>
        <div dangerouslySetInnerHTML={{ __html: html }} />
        <div style={{ marginTop: '35px', marginBottom: '35px' }}>
          <Link to='/'>←Back Home</Link>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
  }
`
